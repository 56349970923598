export default {
    articleCard: {
        click: 'articleCard_click',
    },
    articleHero: {
        linkClick: 'articleHero_link_click',
    },
    backToTopControl: {
        click: 'backToTopControl_click',
    },
    banner: {
        click: 'banner_click',
    },
    benchmarking: {
        addBrand: 'benchmarking_addBrand',
        breakdownPanelShown: 'benchmarking_breakdownPanel_shown',
        removeBrand: 'benchmarking_removeBrand',
        taxonomyTypeChange: 'benchmarking_comparisonCriteria_change',
        sidebarToggle: 'benchmarking_sidebar_toggle',
        viewByChange: 'benchmarking_viewBy_change',
        viewJourney: 'benchmarking_viewJourney',
        viewScreenshots: 'benchmarking_viewScreenshots',
    },
    brandCard: {
        click: 'brandCard_click',
    },
    brandContent: {
        urlClick: 'brandContent_url_click',
        badgeClick: 'brandContent_badge_click',
    },
    carousel: {
        linkClick: 'carousel_link_click',
        next: 'carousel_next',
        prev: 'carousel_prev',
    },
    collectionCard: {
        click: 'collectionCard_click',
    },
    comparison: {
        expandAllClick: 'comparison_expandAll_click',
        breakdownAnchorLinkClick: 'comparison_breakdownAnchorLink_click',
        backLinkClick: 'comparison_backLink_click',
        suggestedJourneyAdded: 'comparison_suggestedJourneyAdded',
    },
    comparisonCard: {
        additionalInformationClick: 'comparisonCard_additionalInformation_click',
        readMoreClick: 'comparisonCard_readMore_click',
    },
    contentSearch: {
        open: 'contentSearch_open',
        close: 'contentSearch_close',
        query: 'contentSearch_query',
        advancedSearchClick: 'contentSearch_advancedSearch_click',
        resultsItemClick: 'contentSearch_resultsItem_click',
        formSubmit: 'contentSearch_form_submit',
        noResults: 'contentSearch_noResults',
        resultsDisplayed: 'contentSearch_resultsDisplayed',
    },
    facetedSearch: {
        facetToggled: 'facetedSearch_facetToggled',
        searchInitiated: 'facetedSearch_searchInitiated',
        resultsReturned: 'facetedSearch_resultsReturned',
        resultsPerPageChange: 'facetedSearch_resultsPerPage_change',
        resultsSortChange: 'facetedSearch_resultsSort_change',
    },
    feedbackControl: {
        sentimentSent: 'feedbackControl_sentiment_sent',
        commentSent: 'feedbackControl_comment_sent',
    },
    filteredSearch: {
        sortChange: 'filteredSearch_sort_change',
        filterItemClick: 'filteredSearch_filterItem_click',
        clear: 'filteredSearch_clear',
        helpShown: 'filteredSearch_help_shown',
        filterItemSearch: 'filteredSearch_filterItem_search',
        refinementClick: 'filteredSearch_refinement_click',
    },
    followControl: {
        itemToggled: 'followControl_itemToggled',
    },
    form: {
        submitted: 'form_submitted',
    },
    heroCarousel: {
        watchNowClick: 'hero_watchNow_click',
    },
    gifMaker: {
        request: 'gifMaker_request',
        requestAborted: 'gifMaker_request_aborted',
        requestSuccess: 'gifMaker_request_success',
        requestFailure: 'gifMaker_request_failure',
    },
    interestToggle: {
        change: 'interestToggle_change',
    },
    journeyCard: {
        click: 'journeyCard_click',
    },
    journeysBasket: {
        show: 'journeysBasket_show',
        hide: 'journeysBasket_hide',
    },
    modal: {
        show: 'modal_show',
        subtitleLinkClick: 'modal_subtitleLink_click',
    },
    navigation: {
        homeClick: 'nav_home_click',
        linkClick: 'nav_link_click',
        subnavTriggerClick: 'nav_subnav-trigger_click',
        playlistCreated: 'nav_playlistCreated',
    },
    onboarding: {
        skip: 'onboarding_skip',
        step: 'onboarding_step',
        formSubmit: 'onboarding_submit',
    },
    playlist: {
        titleClick: 'playlist_title_click',
        brandClick: 'playlist_brand_click',
    },
    playlistControl: {
        listCreated: 'playlistControl_playlistCreated',
        itemToggled: 'playlistControl_itemToggled',
    },
    search: {
        query: 'search_query',
        querySubmit: 'search_query_submit',
        noResults: 'search_noResults',
        suggestionClick: 'search_suggestion_click',
        browseAllClick: 'search_browseAll_click',
        tabShown: 'search_tab_shown',
    },
    screenshotTrigger: {
        click: 'screenshotTrigger_click',
    },
    screenshotViewer: {
        galleryTriggerClick: 'screenshotViewer_gallery_click',
    },
    signup: {
        passSelection: 'onlinePayment_passSelection',
        billingDetailsSubmitted: 'onlinePayment_billingDetailsSubmitted',
        paymentDetailsSubmitted: 'onlinePayment_paymentDetailsSubmitted',
    },
    table: {
        refreshed: 'table_refreshed',
        search: 'table_search',
        sort: 'table_sort',
        treeGridRowShow: 'table_treeGridRow_show',
    },
    tabs: {
        durationOnTab: 'tabs_durationOnTab',
    },
    taxonomyCard: {
        click: 'taxonomyCard_click',
    },
    tooltip: {
        shown: 'tooltip_shown',
    },
    videoContent: {
        relatedLinkClick: 'video_relatedLink_click',
        tagClick: 'video_tag_click',
        featuredInLinkClick: 'video_featuredInLink_click',
        watched: 'video_watched',
        autoScrollScreenshotsChange: 'video_autoScrollScreenshots_change',
        badgeTooltipShown: 'video_badgeTooltip_shown',
        userFeedbackShown: 'video_userFeedback_shown',
    },
    vimeoPlayer: {
        play: 'video_play_click',
        skipBack: 'video_skipBack_click',
        skipForward: 'video_skipForward_click',
        volumeChange: 'video_volume_change',
        volumeMute: 'video_volume_mute',
        playbackSpeedChange: 'video_playbackSpeed_change',
        fullscreenActivated: 'video_fullscreen_activated',
        fullscreenDeactivated: 'video_fullscreen_deactivated',
        cinemaModeActivated: 'video_cinemaMode_activated',
        cinemaModeDeactivated: 'video_cinemaMode_deactivated',
        progressBar: 'video_progressBar_click',
        help: 'video_help_click',
    },
};
